@import "colors";

mat-form-field {
  width: 100%;
}

button {
  border-radius: 7px!important;
  width: 100%;
}
