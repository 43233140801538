mat-label {
  padding-left: 8px;
  font-size: 14px;
  color: #577778;
}

mat-hint {
  color: #372121;
  font-size: 12px;
}
