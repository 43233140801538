/* For use in src/lib/core/theming/_palette.scss */
$md-green: (
        50 : #ebefef,
        100 : #cdd6d7,
        200 : #abbbbc,
        300 : #89a0a1,
        400 : #708b8c,
        500 : #577778,
        600 : #4f6f70,
        700 : #466465,
        800 : #3c5a5b,
        900 : #2c4748,
        A100 : #95fbff,
        A200 : #62f9ff,
        A400 : #2ff7ff,
        A700 : #15f6ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

