::-webkit-scrollbar {
    overflow: hidden;
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    overflow: hidden;
    background: #6F9696;
    border-radius: 10px;
    &:hover {
        background: #577778;
    }
}
