mat-form-field {
  width: 100%;
}

button {
  border-radius: 7px !important;
  width: 100%;
}

mat-label {
  padding-left: 8px;
  font-size: 14px;
  color: #577778;
}

mat-hint {
  color: #372121;
  font-size: 12px;
}

::-webkit-scrollbar {
  overflow: hidden;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  overflow: hidden;
  background: #6F9696;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #577778;
}